import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response, ResponseContentType  } from '@angular/http';
import { User } from '../models/index';
import { BaseUrlClass, BooksApiListClass } from '../common/index';
//import { saveAs } from 'file-saver';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class AxatBookService {
    private baseUrl : string;
    private token : any;
    constructor(private http: Http,
                private baseUrlClass: BaseUrlClass,
                private apiList: BooksApiListClass
              ){this.baseUrl = baseUrlClass.baseUrl;
                this.token =   localStorage.getItem('token');
                //console.log('token key ,'+this.token[0])
              }


   private jwt() {
        //debugger;
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let token = localStorage.getItem('token');
        if (currentUser && token) {
            let headers = new Headers({ 'Authorization': 'JWT '+token });
            return new RequestOptions({ headers: headers });
        }
    }

  //get all currency
  getAllCurrency(){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.currency, options).pipe(
    map((response:Response)=>response.json()));
  }

  //get all place of supply
  getAllPlaceOfSupply(){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.place_of_supply, options).pipe(
    map((response:Response)=>response.json()));
  }

  //get all tax
  getAllTax(){
    ////debugger;
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.tax, options).pipe(
    map((response:Response)=>response.json()));
  }

  //get all invoice type
  getAllInvoiceType(){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.invoice_type, options).pipe(
    map((response:Response)=>response.json()));
  }

  
  // get all customer
  getAllCustomer(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.customer, options).pipe(
  map((response:Response)=>response.json()));
  }

// get all product_category
getAllProductCategory(){
   let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.product_category, options).pipe(
map((response:Response)=>response.json()));
}

//get Financial STart and End Date
getFinancialStartEndDate(){
   let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.financial_start_end_date, options).pipe(
map((response:Response)=>response.json()));
}


//get all company
  getAllCompany(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.company, options).pipe(
  map((response:Response)=>response.json()));
  }

 // getAllCompany (): Observable<any> {
 //    debugger;
 //    return this.http.get(this.baseUrl+this.apiList.company,  {
 //    headers: new HttpHeaders().set('Authorization', this.token),
 //  })
 //      .pipe(
 //        tap(res => this.log(`fetched users`)),
 //        // catchError(this.handleError('getUser', []))
 //      );
 //  }

  private log(message: string) {

    //this.messageService.add('error: ' + message);
  }

// get single company 
 getSingleCompany(id){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.company_setting+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }


// update single company 
 updateSingleCompany(company){
   //debugger;
   console.log("company id is:"+company.id);
     let options = this.jwt()
    const body = JSON.stringify(company);
  return this.http.put(this.baseUrl+this.apiList.company_setting+company.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }


// delete single company 
 deleteSingleCompany(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.company_setting+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }



// get Income Account Information
  //income_account
  getAllIncomeAccount(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.income_account, options).pipe(
  map((response:Response)=>response.json()));
  }

  // get all hsn code

  getAllHSNCode(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.hsn, options).pipe(
  map((response:Response)=>response.json()));

  }

  // get Expense Account Information
  getAllExpenseAccount(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.expense_account, options).pipe(
  map((response:Response)=>response.json()));
  }

getAllExpenseAccountType(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.expense_account_type, options).pipe(
  map((response:Response)=>response.json()));
  }

//expense_account_type
  // get all product


  getAllProduct(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.inventory, options).pipe(
  map((response:Response)=>response.json()));
  }

// Tax Include on amount 
getTaxIncludeOnAmount(){
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.tax_include, options).pipe(
  map((response:Response)=>response.json()));
  //tax_include
}

// get all invoices
getAllInvoices(first_date, todays_date, type){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.invoice_list+"?first_date="+first_date+"&todays_date="+todays_date+"&type="+type, options).pipe(
  map((response:Response)=>response.json()));
}

// getAllSalesPerson
getAllSalesPerson(){
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.sales_person, options).pipe(
  map((response:Response)=>response.json()));
}


// get user profile details 
getUserProfile(){
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.profile, options).pipe(
  map((response:Response)=>response.json()));
}



// get all expense service type

getAllExpenseType(){
console.log("get All expense type")
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.expense_type, options).pipe(
  map((response:Response)=>response.json()));
}

//fiscal_year
getAllFiscalYear(){
    console.log("get All expense type")
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.fiscal_year, options).pipe(
  map((response:Response)=>response.json()));
}

// Get all accounting method
//accounting_method
getAllAccountingMethod(){
    console.log("get All expense type")
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.accounting_method, options).pipe(
  map((response:Response)=>response.json()));
}


// get all countries 
getAllCountries(){
    console.log("get All expense type")
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.countries, options).pipe(
  map((response:Response)=>response.json()));
}

// make the company default 


private extractContentAll(res: Response, des) {
     var fi = des;
     let blob: Blob = res.blob();
     debugger;
     window['saveAs'](blob,fi);
   }


imvoice_no : any;
// Get single invoice in pdf format 
getSingleInvoiceInPdfFormat(invoice_id, des){
 //debugger;
        let token = localStorage.getItem('token');
   this.imvoice_no = des;
   let headers = new Headers({ 'Authorization': 'JWT ' + token });
   debugger
   let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
 return this.http.get(this.baseUrl+this.apiList.invoice_pdf+invoice_id+"/", options).pipe(
 map((response:Response)=>{
   let blob: Blob = response.blob();
   window['saveAs'](blob, des)
   }));

}

getSingleBillInPdfFormat(des, bill_id){
 //debugger;
   this.imvoice_no = des;
        let token = localStorage.getItem('token');
   let headers = new Headers({ 'Authorization': 'JWT ' + token });
   let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
 return this.http.get(this.baseUrl+this.apiList.get_supplier_bill_pdf+bill_id+"/", options).pipe(
 map((response:Response)=>{
   let blob: Blob = response.blob();
   window['saveAs'](blob, bill_id)
   }));

}


// get gst treatment data
get_gst_tratment(){
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.gst_tratment, options).pipe(
  map((response:Response)=>response.json()));
}


// get gst treatment data
get_expense_gst_treatment(){
   let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.expense_gst_treatment, options).pipe(
map((response:Response)=>response.json()));
}


// start of single invoice functionality 
getSingleInvoice(id){
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.single_invoice+id+"/", options).pipe(
  map((response:Response)=>response.json()));
}

// copy invoice 
getInvoiceCopy(id){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.invoice_copy+id+"/", options).pipe(
map((response:Response)=>response.json()));
}

//inventory_data



getInventoryData(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.inventory_data, options).pipe(
  map((response:Response)=>response.json()));
}

// update single invoice
 updateSingleInvoice(invoice){
   console.log("customer id is:"+invoice.id);
     let options = this.jwt()
    const body = JSON.stringify(invoice);
  return this.http.put(this.baseUrl+this.apiList.single_invoice+invoice.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }
// update footer singnature 
updateFooterSingnature(invoice,footer_singnature){
  console.log("customer id is:"+invoice);
  debugger
let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.update_footer_singnature+invoice+"/",{'footer_singnature':footer_singnature} , options).pipe(
map((response:Response)=>response.json()));  
}


deleteSingleInvoice(id){
      let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.single_invoice+id+"/", options).pipe(
  map((response:Response)=>response.json()));
}




makeSelctedCompanyDefault(company){
      let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.company_default,{'company':company} , options).pipe(
  map((response:Response)=>response.json()));  
}

createUserProfile(profile,fileList){
     let token = localStorage.getItem('token');
     let headers = new Headers({ 'Authorization': 'JWT ' + token});
     let file: File = fileList;
     let formData: FormData = new FormData();
     if(file !== undefined){
       formData.append('uploadFile', file, file.name);
     }
     formData.append('profile',JSON.stringify(profile));
      let options = new RequestOptions({ headers: headers });
  return this.http.post(this.baseUrl+this.apiList.profile, formData, options).pipe(
  map((response:Response)=>response.json()));
}




// createSalesPerson()
createSalesPerson(sales){
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.sales_person, {'sales': sales}, options).pipe(
  map((response:Response)=>response.json()));
}

  // create new customer
  createNewCompany(company){
    //debugger;
    console.log('crate new customer method called'+this.baseUrl+this.apiList.company);
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.company, {'company': company}, options).pipe(
  map((response:Response)=>response.json()));
  }

  // create new customer
  createNewCustomer(customer){
    console.log('crate new customer method called');
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.customer, {'customer': customer}, options).pipe(
  map((response:Response)=>response.json()));
  }

  // cretae new product
  createNewProductCategory(product){
    //debugger;
    console.log('crate new customer method called');
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.product_category, {'product': product}, options).pipe(
  map((response:Response)=>response.json()));
  }


  // cretae new product
  createNewInventory(product){
    //debugger;
    console.log('create new inventory method called');
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.inventory, {'inventory': product}, options).pipe(
  map((response:Response)=>response.json()));
  }

 // create new invoice 

 createNewInvoice(invoice){
   //debugger;
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.create_invoice, {'invoice': invoice}, options).pipe(
  map((response:Response)=>response.json()));
 }

 // get Customer Details 
 getCustomerDetails(id){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.customer_edit+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

// update single company 
 updateSingleCustomer(customer){
   console.log("customer id is:"+customer.id);
     let options = this.jwt()
    const body = JSON.stringify(customer);
  return this.http.put(this.baseUrl+this.apiList.customer_edit+customer.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }


 //delete Single Customer
 deleteSingleCustomer(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.customer_edit+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

  // get Customer Details 
 getProductDetails(id){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.product+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

// update single company 
 updateSingleProduct(product){
   console.log("product id is:"+product.id);
     let options = this.jwt()
    const body = JSON.stringify(product);
  return this.http.put(this.baseUrl+this.apiList.product+product.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }


 //delete Single Customer
 deleteSingleProduct(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.product+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

/// get all inventory list
getInventoryQuantityInHand(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.inventory_quantity_in_hand, options).pipe(
  map((response:Response)=>response.json()));   
  }

getInventoryOutOfStockList(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.inventory_out_of_stock_list, options).pipe(
  map((response:Response)=>response.json()));   
  }

getInventoryLowStockList(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.inventory_low_stock_list, options).pipe(
  map((response:Response)=>response.json()));   
  }


getInventoryTotal(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.inventory_total, options).pipe(
  map((response:Response)=>response.json()));   
  }

  // customer analytics data
getContactsCustomerData(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.customer_all_data, options).pipe(
  map((response:Response)=>response.json()));   
  }

  // get customers list 
getContactsVendorData(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.vendor_all_data, options).pipe(
  map((response:Response)=>response.json()));   
  }



  // get vendors list

getCustomerAnalyticData(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.customer_analytic_data, options).pipe(
  map((response:Response)=>response.json()));   
  }



getProductAnalyticData(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.product_analytic_data
, options).pipe(
  map((response:Response)=>response.json()));   
  }

 // get Inv No Details 
 getInvoiceNumber(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_invoice_no, options).pipe(
  map((response:Response)=>response.json()));   
  }


// get all terms 
 getAllTerms(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.terms, options).pipe(
  map((response:Response)=>response.json()));   
  }


// create Invoice No
createNewInvoiceNo(invoice_no){
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.get_invoice_no, {'invoice_no': invoice_no}, options).pipe(
  map((response:Response)=>response.json()));
}

//get Analytical Data of Invoice
getInvoiceAnalyticData(start_date, end_date){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.invoice_analytic_data+"?start_date="+start_date+"&end_date="+end_date
, options).pipe(
  map((response:Response)=>response.json()));   
  }

 //get all invoice type
  getTotalAmountInvoices(val){
    //debugger;
       let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.totalamount_invoice,{'val': val}, options).pipe(
    map((response:Response)=>response.json()));
  }

  //get all invoice type
  getPaidAmountInvoices(val){
       let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.paidamount_invoice,{'val': val}, options).pipe(
    map((response:Response)=>response.json()));
  }

 //get all invoice type
  getDueAmountInvoices(val){
       let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.dueamount_invoice, {'val': val},options).pipe(
    map((response:Response)=>response.json()));
  }


getSingleInvoiceProduct(id){
   let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.single_invoice_products+id+"/", options).pipe(
  map((response:Response)=>response.json()));

}

getAllPaidThroughType(){
   let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.paid_through, options).pipe(
  map((response:Response)=>response.json()));

}

getAllExpenses(first_date, todays_date){
   let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.expenses+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));

}

postExpenses(data){
  //debugger;
   let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.expenses,{'expense': data}, options).pipe(
  map((response:Response)=>response.json()));

}


//Create Make Payment accord to invoice
 createMakePayment(invoice){
   //debugger;
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.make_payment, {'payment': invoice}, options).pipe(
  map((response:Response)=>response.json()));
 }


 //get all reports
  getSalesByCustomer(start_date, end_date){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.sales_by_customer+"?start_date="+start_date+"&end_date="+end_date, options).pipe(
    map((response:Response)=>response.json()));
  }

  getSalesBySalesPerson(start_date, end_date){
       let options = this.jwt()
      return this.http.get(this.baseUrl+this.apiList.sales_by_salesperson+"?start_date="+start_date+"&end_date="+end_date, options).pipe(
      map((response:Response)=>response.json()));
  }

  //Payment List

  getAllPayments(first_date, todays_date){
     let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.payment_list+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
    map((response:Response)=>response.json()));
  }

 createNewPayment(payment){
   //debugger;
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.payment_list, {'payment': payment}, options).pipe(
  map((response:Response)=>response.json()));
 }

 // start of single invoice functionality 
getSinglePayment(id){
      let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.single_payment+id+"/", options).pipe(
  map((response:Response)=>response.json()));
}

deleteSinglePayment(id){
      let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.single_payment+id+"/", options).pipe(
  map((response:Response)=>response.json()));
}

// update single invoice
 updateSinglePayment(payment){
   console.log("customer id is:"+payment.id);
     let options = this.jwt()
    const body = JSON.stringify(payment);
  return this.http.put(this.baseUrl+this.apiList.single_payment+payment.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }

// Supplier module 

//supplier_bill

 createNewSupplierBill(supplier){
   //debugger;
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.supplier_bill, {'supplier': supplier}, options).pipe(
  map((response:Response)=>response.json()));
 }


  getAllBills(first_date, todays_date, type){
     let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.supplier_bill+"?first_date="+first_date+"&todays_date="+todays_date+"&type="+type, options).pipe(
    map((response:Response)=>response.json()));
  }

// get , update , delete expenses
 getSingleExpense(id){
  debugger
   let options = this.jwt()
 return this.http.get(this.baseUrl+this.apiList.expense_update+id+"/", options).pipe(
 map((response:Response)=>response.json()));   
 }


updateSingleExpense(expense){
  console.log("customer id is:"+expense.id);
    let options = this.jwt()
   const body = JSON.stringify(expense);
 return this.http.put(this.baseUrl+this.apiList.expense_update+expense.id+"/", body, options).pipe(
 map((response:Response)=>response.json()));   
 }

deleteSingleExpense(id){
     let options = this.jwt()
 return this.http.delete(this.baseUrl+this.apiList.expense_update+id+"/", options).pipe(
 map((response:Response)=>response.json()));
}

getSinglePaymentInPdfFormat(payment_id, des){
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let token = localStorage.getItem('token');
   this.imvoice_no = des
   let headers = new Headers({ 'Authorization': 'JWT ' + token });
   let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
 return this.http.get(this.baseUrl+this.apiList.payment_pdf+payment_id+"/", options).pipe(
 map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));
}

// get supplier info , edit , delete info

getSingleSupplierBill(id){
  debugger
  let options = this.jwt()
 return this.http.get(this.baseUrl+this.apiList.get_supplier_bill+id+"/", options).pipe(
 map((response:Response)=>response.json()));   
 }


updateSingleSupplierBill(bill){
  console.log("customer id is:"+bill.id);
   let options = this.jwt()
   const body = JSON.stringify(bill);
 return this.http.put(this.baseUrl+this.apiList.get_supplier_bill+bill.id+"/", body, options).pipe(
 map((response:Response)=>response.json()));   
 }

deleteSingleSupplierBill(id){
     let options = this.jwt()
 return this.http.delete(this.baseUrl+this.apiList.get_supplier_bill+id+"/", options).pipe(
 map((response:Response)=>response.json()));
}

//get supplier bill products related to the bill

getAllSupplierProducts(id){
  //debugger;
 let options = this.jwt()
 return this.http.get(this.baseUrl+this.apiList.get_supplier_products+id+"/", options).pipe(
 map((response:Response)=>response.json()));   
 //get_supplier_products
}


//bill analytics data 

getAllBillAnalyticsData(start_date, end_date){
  //debugger;
  let options = this.jwt()
 return this.http.get(this.baseUrl+this.apiList.bill_analytics+"?start_date="+start_date+"&end_date="+end_date, options).pipe(
 map((response:Response)=>response.json()));   
}

//Create Bill Payment accord to bills
 createBillPayment(payment){
   //debugger;
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.bill_payment, {'payment': payment}, options).pipe(
  map((response:Response)=>response.json()));
 }

createBillsPayment(payment){
   //debugger;
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.bill_payment_list, {'payment': payment}, options).pipe(
  map((response:Response)=>response.json()));
 }

getAllBillPayments(first_date, todays_date){
  //debugger;
  let options = this.jwt()
 return this.http.get(this.baseUrl+this.apiList.bill_payment_list+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
 map((response:Response)=>response.json()));   
}

getSingleBillPayment(id){
  debugger
  let options = this.jwt()
 return this.http.get(this.baseUrl+this.apiList.bill_payment_update+id+"/", options).pipe(
 map((response:Response)=>response.json()));   
 }


updateSingleBillPayment(bill){
  console.log("customer id is:"+bill.id);
   let options = this.jwt()
   const body = JSON.stringify(bill);
 return this.http.put(this.baseUrl+this.apiList.bill_payment_update+bill.id+"/", body, options).pipe(
 map((response:Response)=>response.json()));   
 }

deleteSingleBillPayment(id){
     let options = this.jwt()
 return this.http.delete(this.baseUrl+this.apiList.bill_payment_update+id+"/", options).pipe(
 map((response:Response)=>response.json()));
}

getSingleBillPaymentInPdfFormat(payment_id, des){
  let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let token = localStorage.getItem('token');
   this.imvoice_no = des
   let headers = new Headers({ 'Authorization': 'JWT ' + token });
   let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
 return this.http.get(this.baseUrl+this.apiList.bill_payment_pdf+payment_id+"/", options).pipe(
 map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));
}
 invoice_pr_id:any;
 deleteSingleInvoiceProducts(product){
   if(product.id){
   this.invoice_pr_id =product.id
 }else{this.invoice_pr_id=-1}
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.single_invoice_products+this.invoice_pr_id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }


 getAllPaymentTerm(){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.get_payment_method, options).pipe(
    map((response:Response)=>response.json()));
  }

   getAllDepositTo(){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.get_depositto, options).pipe(
    map((response:Response)=>response.json()));
  }

  getCompanyCurrency(){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.company_currency, options).pipe(
    map((response:Response)=>response.json()));
  }

  invoiceNumberExists(invoice_no, invoice_type){
       let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.invoice_no_exists,{'invoice_no': invoice_no, 'invoice_type': invoice_type}, options).pipe(
    map((response:Response)=>response.json()));
  }


 getDashboardDetails(val){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.dashboard_view+"?val="+val, options).pipe(
  map((response:Response)=>response.json()));
}


//gstr pdf

getGSTR3BPDF(des){
        let token = localStorage.getItem('token');
 //debugger;
   this.imvoice_no = des;
   let headers = new Headers({ 'Authorization': 'JWT ' + token });
   let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
    return this.http.get(this.baseUrl+this.apiList.gstr_pdf, options).pipe(
 map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));

}

getTop5ExpensesData(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.top_5_expenses_data, options).pipe(
  map((response:Response)=>response.json()));

}



getCashFlowData(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.cash_flow_data, options).pipe(
  map((response:Response)=>response.json()));

}


getIncomeAdnExpensesData(){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.income_and_expense_data, options).pipe(
  map((response:Response)=>response.json()));

}

createNewTax(tax){
     let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.tax,{'tax': tax}, options).pipe(
    map((response:Response)=>response.json()));
}

editNewTax(tax:any){
     let options = this.jwt()
    return this.http.put(this.baseUrl+this.apiList.tax_detail+tax.id+"/",{'tax': tax}, options).pipe(
    map((response:Response)=>response.json()));
}

deleteNewTax(tax_id){
     let options = this.jwt()
    return this.http.delete(this.baseUrl+this.apiList.tax_detail+tax_id+"/", options).pipe(
    map((response:Response)=>response.json()));
}

gstTaxAnalyticsData(first_date, todays_date){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.tax_analytics_data+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}




//Charts Api
getBarChartYaxis(type){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.bar_chart_yaxis+"?type="+type, options).pipe(
    map((response:Response)=>response.json()));
  }

   createNewPassword(password){
    //debugger;
    console.log('crate new customer method called'+this.baseUrl+this.apiList.company);
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.change_password, {'password': password}, options).pipe(
  map((response:Response)=>response.json()));
  }


getGSTRReport(current_month,current_year){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_gstr_report+"?first_date="+current_month+"&todays_date="+current_year, options).pipe(
  map((response:Response)=>response.json()));
}

getGSTRBillReport(current_month,current_year){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_gstr_bill_report+"?first_date="+current_month+"&todays_date="+current_year, options).pipe(
  map((response:Response)=>response.json()));
}
getGSTRInterReport(current_month,current_year){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_gstr_inter_report+"?first_date="+current_month+"&todays_date="+current_year, options).pipe(
  map((response:Response)=>response.json()));
}
getGSTRITCReport(current_month,current_year){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_gstr_itc_report+"?first_date="+current_month+"&todays_date="+current_year, options).pipe(
  map((response:Response)=>response.json()));
}

getTopExpenseView(val){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.top_expense_view+"?val="+val, options).pipe(
  map((response:Response)=>response.json()));
}

getExpenseList(type){
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.expense_drilldown+"?type="+type, options).pipe(
  map((response:Response)=>response.json()));
}

getB2BList(first_date, todays_date){
  //debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.b2b_gstr+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}

getB2BLargeList(first_date, todays_date){
  //debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.b2b_large_gstr+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}

getB2BSmallList(first_date, todays_date){
  //debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.b2b_small_gstr+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}

getExportGSTRList(first_date, todays_date){
  //debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.export_gstr+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}

getB2BOtherList(first_date, todays_date){
  //debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.b2b_other_gstr+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}

getExemptList(first_date, todays_date){
  ////debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.exempt_gstr+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}

getHSNList(first_date, todays_date){
  ////debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.hsn_gstr+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}

getAllLenders(first_date, todays_date){
  ////debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.lenders_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}



 createNewLenders(lenders){
   ////debugger;
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.lenders_view, {'lenders': lenders}, options).pipe(
  map((response:Response)=>response.json()));
 }

getSingleLend(id){
  //debugger
  let options = this.jwt()
 return this.http.get(this.baseUrl+this.apiList.get_lenders_view+id+"/", options).pipe(
 map((response:Response)=>response.json()));   
 }

updateSingleLend(lend){
   console.log("customer id is:"+lend.id);
     let options = this.jwt()
    const body = JSON.stringify(lend);
  return this.http.put(this.baseUrl+this.apiList.get_lenders_view+lend.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }



deleteSingleLend(id){
      let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.get_lenders_view+id+"/", options).pipe(
  map((response:Response)=>response.json()));
}



// Get single invoice in pdf format 
getSingleLenderInPdfFormat(lend_id, des){
  let token = localStorage.getItem('token');
 ////debugger;
   this.imvoice_no = des;
   let headers = new Headers({ 'Authorization': 'JWT ' + token });
   let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
 return this.http.get(this.baseUrl+this.apiList.lender_pdf_view+lend_id+"/", options).pipe(
 map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));

}

getAllLendersPayment(first_date, todays_date){
  ////debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.lend_payment_list+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>response.json()));
}



 createNewLendersPayment(lenders){
   ////debugger;
     let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.lend_payment_list, {'payment': lenders}, options).pipe(
  map((response:Response)=>response.json()));
 }

getSingleLendPayment(id){
  //debugger
   let options = this.jwt()
 return this.http.get(this.baseUrl+this.apiList.lend_payment_view+id+"/", options).pipe(
 map((response:Response)=>response.json()));   
 }

updateSingleLendPayment(lend){
   console.log("customer id is:"+lend.id);
     let options = this.jwt()
    const body = JSON.stringify(lend);
  return this.http.put(this.baseUrl+this.apiList.lend_payment_view+lend.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }



deleteSingleLendPayment(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.lend_payment_view+id+"/", options).pipe(
  map((response:Response)=>response.json()));
}

getSingleLenderPaymentInPdfFormat(lend_id, des){
  let token = localStorage.getItem('token');
 ////debugger;
   this.imvoice_no = des;
   let headers = new Headers({ 'Authorization': 'JWT ' + token });
   let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
 return this.http.get(this.baseUrl+this.apiList.lender_payment_pdf_view+lend_id+"/", options).pipe(
 map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));

}


getLendPaymentAccordLend(lend_id){
  ////debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.lender_payment_accord_lend+"?slug="+lend_id, options).pipe(
  map((response:Response)=>response.json()));
}

getAutoLendNo(){
  ////debugger;
     let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.lender_number_auto, options).pipe(
  map((response:Response)=>response.json()));
}


private extractContentAllExcel(res: Response, des) {
    debugger;
     var fi = des;
     let blob = res.blob();
     let data =  new Blob([blob], {type: 'application/csv'});
     window['saveAs'](data, fi);
   }


getInvoiceExcel(first_date, todays_date){
    let token = localStorage.getItem('token');
    let headers = new Headers({ 'Authorization': 'JWT ' + token });
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
  return this.http.get(this.baseUrl+this.apiList.invoice_excel_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>this.extractContentAllExcel(response,'Invoices.csv')));
}


getBillsExcel(first_date, todays_date){
    let token = localStorage.getItem('token');
    let headers = new Headers({ 'Authorization': 'JWT ' + token });
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
  return this.http.get(this.baseUrl+this.apiList.bill_excel_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>this.extractContentAllExcel(response, 'Bills.csv')));
}

getExpenseExcel(first_date, todays_date){
    let token = localStorage.getItem('token');
    let headers = new Headers({ 'Authorization': 'JWT ' + token });
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
  return this.http.get(this.baseUrl+this.apiList.expense_excel_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
  map((response:Response)=>this.extractContentAllExcel(response, 'Expenses.csv')));
}

setInvoiceNoAccordInvoices(invoice_type){
  ////debugger;
  let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.set_invoiceno_accord_invoice+"?invoice_type="+invoice_type, options).pipe(
  map((response:Response)=>response.json()));
}

getCurrencyCompanyWise(){
  ////debugger;
  let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.currency_company_wise, options).pipe(
  map((response:Response)=>response.json()));
}

addCurrencyCompanyWise(currency){
  ////debugger;
    let options = this.jwt()
 return this.http.post(this.baseUrl+this.apiList.currency_company_wise, {'currency': currency}, options).pipe(
 map((response:Response)=>response.json()));
}

deleteSingleCurrency(id){
  let options = this.jwt()
return this.http.delete(this.baseUrl+this.apiList.currency_company_wise_details+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

// create Invoice No
updateGstTreatment(contact_gst){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.update_gst_treatment, {'contact_gst': contact_gst}, options).pipe(
map((response:Response)=>response.json()));
}

getAllTaxAccount(){
  ////debugger;
  let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_all_tax_account, options).pipe(
  map((response:Response)=>response.json()));
}

// Estimate Services For Connecting Backend Api's //

getAllEstimates(first_date, todays_date){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.estimate_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
map((response:Response)=>response.json()));
}

createEstimate(estimate){
let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.estimate_view, {'estimate': estimate}, options).pipe(
map((response:Response)=>response.json()));
}

getSingleEstimate(id){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.estimate_details+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

updateSingleEstimate(estimate){
  console.log("customer id is:"+estimate.id);
    let options = this.jwt()
   const body = JSON.stringify(estimate);
 return this.http.put(this.baseUrl+this.apiList.estimate_details+estimate.id+"/", body, options).pipe(
 map((response:Response)=>response.json()));   
 }


deleteSingleEstimate(id){
  let options = this.jwt()
return this.http.delete(this.baseUrl+this.apiList.estimate_details+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

getEstimateNumber(){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.get_estimate_no, options).pipe(
map((response:Response)=>response.json()));   
}


// create Credit No
createNewEstimateNo(estimate_no){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.get_estimate_no, {'estimate_no': estimate_no}, options).pipe(
map((response:Response)=>response.json()));
}

EstimateNumberExists(estimate_no){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.estimate_no_exists,{'estimate_no': estimate_no}, options).pipe(
map((response:Response)=>response.json()));
}

 deleteSingleEstimateProduct(product){
   let estimate_id = 0
   if(product.id){
   estimate_id =product.id
 }else{estimate_id=-1}
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.single_estimate_product+estimate_id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

  downloadEstimatePDF(estimate_id, des){
     let token = localStorage.getItem('token');
     let estimate = des;
     let headers = new Headers({ 'Authorization': 'JWT ' + token });
     let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
   return this.http.get(this.baseUrl+this.apiList.estimate_pdf_view+estimate_id+"/", options).pipe(
   map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));
  }

  createEstimateToInvoice(invoice){
    let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.create_estimate_to_invoice, {'invoice': invoice}, options).pipe(
  map((response:Response)=>response.json()));
  }

  deleteSelectedEstimates(estimates){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_estimates, estimates, options).pipe(
  map((response:Response)=>response.json()));
  }


// End of Estimate Servicess //


//Multiple Selected Functionalities//
deleteSelectedContacts(contacts){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_contacts, contacts, options).pipe(
  map((response:Response)=>response.json()));
  }

deleteSelectedProducts(products){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_products, products, options).pipe(
  map((response:Response)=>response.json()));
  }

deleteSelectedInvoices(invoice){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_invoices, invoice, options).pipe(
  map((response:Response)=>response.json()));
  }

  deleteSelectedInvPayment(invoice){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_invpayment, invoice, options).pipe(
  map((response:Response)=>response.json()));
  }


deleteSelectedBills(bills){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_bills, bills, options).pipe(
  map((response:Response)=>response.json()));
  }

deleteSelectedBillsPayment(bills){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_billspayment, bills, options).pipe(
  map((response:Response)=>response.json()));
  }

 deleteSelectedExpenses(expense){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_expenses, expense, options).pipe(
  map((response:Response)=>response.json()));
  }


deleteSelectedLend(lend){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_lend, lend, options).pipe(
  map((response:Response)=>response.json()));
  }

deleteSelectedLPayment(payment){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_lpayment, payment, options).pipe(
  map((response:Response)=>response.json()));
  }

//            End                 //



// Incomes and Expenses List //

getIncomeList(val){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_income_list+"?type="+val, options).pipe(
  map((response:Response)=>response.json()));
}

getBillsExpenseList(val){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_bills_expense_list+"?type="+val, options).pipe(
  map((response:Response)=>response.json()));
}

// End of the Income & Expenses List //

}
