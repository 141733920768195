
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response, ResponseContentType  } from '@angular/http';
import { User } from '../models/index';
import { BaseUrlClass, AdminApiListClass } from '../common/index';
//import { saveAs } from 'file-saver';
@Injectable()
export class AdminServices {
    private baseUrl : string;
    private token : any;
    constructor(private http: Http,
                private baseUrlClass: BaseUrlClass,
                private apiList: AdminApiListClass
              ){this.baseUrl = baseUrlClass.baseUrl;
                this.token =   localStorage.getItem('token');
                //console.log('token key ,'+this.token[0])
              }


  //get all currency
  getAllUserList(){
      let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
      let options = new RequestOptions({ headers: headers });
    return this.http.get(this.baseUrl+this.apiList.user_list, options).pipe(
    map((response:Response)=>response.json()));
  }

   getAllPaidUserList(){
      let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
      let options = new RequestOptions({ headers: headers });
    return this.http.get(this.baseUrl+this.apiList.paid_user_list, options).pipe(
    map((response:Response)=>response.json()));
  }

   getAllTrialUserList(){
      let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
      let options = new RequestOptions({ headers: headers });
    return this.http.get(this.baseUrl+this.apiList.trial_user_list, options).pipe(
    map((response:Response)=>response.json()));
  }

 getAllMEUserList(){
      let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
      let options = new RequestOptions({ headers: headers });
    return this.http.get(this.baseUrl+this.apiList.me_user_list, options).pipe(
    map((response:Response)=>response.json()));
  }


   getAllCompaniesList(){
      let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
      let options = new RequestOptions({ headers: headers });
    return this.http.get(this.baseUrl+this.apiList.companies_list, options).pipe(
    map((response:Response)=>response.json()));
  }


  createNewPayment(payment){
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.post(this.baseUrl+this.apiList.payment_view,{'payment':payment} , options).pipe(
  map((response:Response)=>response.json())); 
  }


getAllPayments(){
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.get(this.baseUrl+this.apiList.payment_view, options).pipe(
  map((response:Response)=>response.json())); 
  }

getPaymentsTrial(){
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.get(this.baseUrl+this.apiList.get_payment_trial, options).pipe(
  map((response:Response)=>response.json())); 
  }

updateTrial(){
  this.token =   localStorage.getItem('token');
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.get(this.baseUrl+this.apiList.update_trial , options).pipe(
  map((response:Response)=>response.json())); 
  }


updateTrialAdmin(user){
  this.token =   localStorage.getItem('token');
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.get(this.baseUrl+this.apiList.update_trial_admin+user.user.id+"/" , options).pipe(
  map((response:Response)=>response.json())); 
  }

getUserAsPerUser(){
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.get(this.baseUrl+this.apiList.user_as_per_user, options).pipe(
  map((response:Response)=>response.json())); 
  }

inviteUser(user){
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.post(this.baseUrl+this.apiList.user_as_per_user,{'user':user} , options).pipe(
  map((response:Response)=>response.json())); 
}

getUserRolesApi(){
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.get(this.baseUrl+this.apiList.user_roles_api, options).pipe(
  map((response:Response)=>response.json())); 
  }

createUserRoles(role){
     let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
     let options = new RequestOptions({ headers: headers });
  return this.http.post(this.baseUrl+this.apiList.user_roles_api,{'role':role} , options).pipe(
  map((response:Response)=>response.json())); 
}

getSingleUser(id){
   debugger
   let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
    let options = new RequestOptions({ headers: headers });
  return this.http.get(this.baseUrl+this.apiList.update_user_as_per_user+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }


// update single company 
 updateSingleUser(user){
   //debugger;
   console.log("company id is:"+user.id);
    let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
    let options = new RequestOptions({ headers: headers });
    const body = JSON.stringify(user);
  return this.http.put(this.baseUrl+this.apiList.update_user_as_per_user+user.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }


// delete single company 
 deleteSingleUser(id){
   let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
    let options = new RequestOptions({ headers: headers });
  return this.http.delete(this.baseUrl+this.apiList.update_user_as_per_user+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }


getSingleRole(id){
   let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
    let options = new RequestOptions({ headers: headers });
  return this.http.get(this.baseUrl+this.apiList.user_roles_update_api+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }


// update single company 
 updateSingleRole(role){
   //debugger;
   console.log("company id is:"+role.id);
    let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
    let options = new RequestOptions({ headers: headers });
    const body = JSON.stringify(role);
  return this.http.put(this.baseUrl+this.apiList.user_roles_update_api+role.id+"/", body, options).pipe(
  map((response:Response)=>response.json()));   
  }


// delete single company 
 deleteSingleRole(id){
   let headers = new Headers({ 'Authorization': 'JWT ' + this.token });
    let options = new RequestOptions({ headers: headers });
  return this.http.delete(this.baseUrl+this.apiList.user_roles_update_api+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }


}