// all constants declare here
export class ApiListClass {
  constructor() {
    console.log("Hello Dhananjay");
  }

  public sign_up : string = "/crm/api/v1/signup/";   //post method called
  public login   : string = "/crm/api/v1/login/";
  public profile : string = "/crm/api/v1/user_profile/";  // get , post , delete method called
  public forgot : string = "/crm/api/v1/forgot/";
  public reset : string = "/crm/api/v1/reset/";
  public reset_success : string = "/crm/api/v1/reset_success/";
  public is_email_exist : string = "/crm/api/v1/is_email_exist";
  public company_count : string = "/books/api/v1/company_count/";
  
}
