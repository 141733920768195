import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AxatBookService, AdminServices, UserService } from '../services/index';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-axat-cloud-app',
  templateUrl: './axat-cloud-app.component.html',
  styleUrls: ['./axat-cloud-app.component.css']
})
export class AxatCloudAppComponent implements OnInit {

  counter : number = 0;
  loading = false;

  constructor(private router : Router, 
              private userService : UserService,
              private adminService : AdminServices,
              private axatBookService :AxatBookService,) { }

  ngOnInit() {
    this.getCompanyCount();
    this.getAllCompanyList();
    //this.getPaymentTrials();
    //this.OpenDialog();
     //
  }



  checkNavigationIsItFirstTime(){
    this.loading = true;
    if (this.counter <= 0){
        console.log("counter is equal to zero");
        
        this.router.navigate(['/book/addcompany/']);
    }else{
      let user = JSON.parse(localStorage.getItem('currentUser'));
      if (user.id === this.all_company_list.user_id){
        console.log("counter is greater than or equal to one");
      this.router.navigate(['/book/dashboard']);  
      }else{
        this.router.navigate(['/book/managecompany']); 
      }
      
      }
  }


  getCompanyCount(){
   this.userService.getAllCompanyCount()
    .subscribe((res)=>{
      this.counter = res.company_count;
    })
  
}

all_company_list : any;
temp: any;
//method
getAllCompanyList(){
  console.log("get all company list");
  this.axatBookService.getAllCompany()
  .subscribe((res)=>{
    this.all_company_list = res;
    
    for(let i = 0 ; i <= this.all_company_list.length; i++){
      if(this.all_company_list[i].is_default === true ){
        this.all_company_list = this.all_company_list[i].company;
      }else{
        console.log("-----------no-----------------")
      }
    }
    
  })
}

//   
}