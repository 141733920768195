import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
//import { Ng2AutoCompleteModule } from 'ng2-auto-complete';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SuiCheckboxModule, SuiRatingModule, SuiModule } from 'ng2-semantic-ui';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import {
  MatFormFieldModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';

import {CdkTableModule} from '@angular/cdk/table';
import { DataTableModule } from 'angular2-datatable';
//import { DatePickerModule } from 'ng2-datepicker';
import { DataFilterPipe }   from './pipe/data-filter.pipe';



import { AppComponent } from './app.component'
import { FormsModule, ReactiveFormsModule }   from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { AppRoutingModule }        from './app.routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// start of authentication service
import { HttpModule } from '@angular/http'
// used to create fake backend
import { fakeBackendProvider } from './helpers/index';
import { MockBackend, MockConnection } from '@angular/http/testing';
import { BaseRequestOptions } from '@angular/http';

import { AlertComponent } from './directives/index';
import { AuthGuard } from './guards/index';
import { AlertService, AuthenticationService,TimesheetService, OrderService, UserService, AxatBookService, AdminServices, SharedService, CreditNoteService } from './services/index';
import { ForgotComponent } from './forgot/forgot.component';
// base url class
import { BaseUrlClass, ApiListClass, BooksApiListClass, AdminApiListClass } from './common/index';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

import { EqualValidator } from './signup/equal-validator.directive';
import { AxatCloudAppComponent } from './axat-cloud-app/axat-cloud-app.component';


//import { AxatCloudAppComponent } from './axat-cloud-app/axat-cloud-app.component';

// basic header
import { HeaderBasicComponent } from './header-basic/header-basic.component';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';
import { JwtModule,JwtHelperService, JwtModuleOptions } from '@auth0/angular-jwt';
import { NgHttpLoaderModule } from 'ng-http-loader/ng-http-loader.module';

export function gettoken (){
return localStorage.getItem('token');
};
const jwtConf: JwtModuleOptions = {
config: {
tokenGetter: gettoken,
whitelistedDomains: ['localhost:4200']
}
}


@NgModule({
  declarations: [
    EqualValidator,
    AppComponent,
    AlertComponent,
    HeaderComponent,
    FooterComponent,
    AxatCloudAppComponent,
    SignupComponent,
    LoginComponent,
    ProfileComponent,
    ForgotComponent,
    ResetPasswordComponent,
    DataFilterPipe,
    HeaderBasicComponent,
    CommingSoonComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    HttpModule,
    HttpClientModule,
    BrowserModule,
    NgbModule.forRoot(),
    SuiModule,
    SuiCheckboxModule,
    SuiRatingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    //DatePickerModule,
    MatNativeDateModule,
    MatDatepickerModule,

    //MaterialModule,
    DataTableModule,
    JwtModule.forRoot(jwtConf),
    NgHttpLoaderModule
   // Ng2AutoCompleteModule
  ],
  providers: [AuthGuard,
        AlertService,
        AuthenticationService,
        UserService,
        AxatBookService,
        MockBackend,
        BaseRequestOptions,
        BaseUrlClass,
        ApiListClass,
        BooksApiListClass,
        AdminApiListClass,
        AdminServices,
        SharedService,
        HttpClientModule,
        CreditNoteService,
        OrderService,
        TimesheetService
        //JwtHelperService,
        //{provide: DateAdapter, useClass: MyDateAdapter},
    //{provide: MD_DATE_FORMATS, useValue: MY_DATE_FORMATS},
],

  exports :[ HeaderBasicComponent,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule, ]

})
export class AppModule { }
