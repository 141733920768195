
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';

import { BaseUrlClass, ApiListClass } from '../common/index';

@Injectable()
export class AuthenticationService {
    private base_url : string;
    constructor(private http: Http, private base: BaseUrlClass, private apiListClass: ApiListClass) {
      this.base_url = base.baseUrl;
     }

    login(username: string, password: string) {
    
        let headers = new Headers({'Content-type' : 'application/json'});
        return this.http.post(this.base_url+this.apiListClass.login, JSON.stringify({ email: username, password: password }),
          {
          headers: headers,
          }
          ).pipe(
            map((response: Response) => {
           
                // login successful if there's a jwt token in the response
                let user = response.json();
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    
                    //console.log("token is:", user.token.key);
                    let new_token : string = user.token//.key.replace('"","');
                    console.log("new token is:", new_token);
                    localStorage.setItem('token', new_token);
                    localStorage.setItem('currentUser', JSON.stringify(user.user));

                }
                 return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
        
        localStorage.removeItem('currentUser');

          }



}
