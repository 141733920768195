import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService, UserService } from '../services/index';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent implements OnInit {
  model: any = {};
  loading = false;
  isForgotSuccess : boolean = false;
  isForgotError : boolean = false;
  showDive : boolean = true;
  hideDive(){
    this.showDive = false;
  }
  constructor(private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService) { }

  ngOnInit() {
    this.authenticationService.logout();
  }
  forgot(){
    this.loading = true;
    console.log("forgot method called");
    this.userService.forgot(this.model.email)
        .subscribe(
            data => {
                  this.isForgotSuccess = true;
                  this.loading = false;
                  setTimeout(()=>{
                      this.isForgotSuccess = false;
                    },5000);
                  console.log("debugger method called")
              //  this.router.navigate(['dashboard']);
            },
            error => {
              //  this.alertService.error(error);
                console.log("error in sending email");
                this.loading = false;
                this.isForgotError = true;
                setTimeout(()=>{
                    this.isForgotError = false;
                  },5000);
            });
  }
}
