import {Component} from '@angular/core'
import { FormControl } from '@angular/forms';
import { Spinkit } from 'ng-http-loader/spinkits';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
 public spinkit = Spinkit

  constructor() {
  }

  ngOnInit(){
  	// debugger
  	console.log("--------"+this.spinkit);
  }
}
