import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response, ResponseContentType  } from '@angular/http';
import { User } from '../models/index';
import { BaseUrlClass, BooksApiListClass } from '../common/index';
//import { saveAs } from 'file-saver';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class OrderService {
    private baseUrl : string;
    private token : any;
    constructor(private http: Http,
                private baseUrlClass: BaseUrlClass,
                private apiList: BooksApiListClass
              ){this.baseUrl = baseUrlClass.baseUrl;
                this.token =   localStorage.getItem('token');
                //console.log('token key ,'+this.token[0])
              }

   private jwt() {
       
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let token = localStorage.getItem('token');
        if (currentUser && token) {
            let headers = new Headers({ 'Authorization': 'JWT '+token });
            return new RequestOptions({ headers: headers });
        }
    }

  getAllPOrder(first_date, todays_date){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.porder_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
    map((response:Response)=>response.json()));
  }

createPOrder(porder){
    let options = this.jwt()
 return this.http.post(this.baseUrl+this.apiList.porder_view, {'porder': porder}, options).pipe(
 map((response:Response)=>response.json()));
}

 getSinglePOrder(id){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.porder_details+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

 updateSinglePOrder(porder){
    console.log("customer id is:"+porder.id);
      let options = this.jwt()
     const body = JSON.stringify(porder);
   return this.http.put(this.baseUrl+this.apiList.porder_details+porder.id+"/", body, options).pipe(
   map((response:Response)=>response.json()));   
   }

   //delete Single Customer
 deleteSinglePOrder(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.porder_details+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

  getPOrderNumber(){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.get_porder_no, options).pipe(
  map((response:Response)=>response.json()));   
  }
  
  // create Order No
  createNewPOrderNo(porder_no){
    let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.get_porder_no, {'porder_no': porder_no}, options).pipe(
  map((response:Response)=>response.json()));
  }
  
  porderNumberExists(porder_no){
    let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.porder_no_exists,{'porder_no': porder_no}, options).pipe(
  map((response:Response)=>response.json()));
  }

  createOrderToBill(bill){
    let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.porder_to_bill,{'bill': bill}, options).pipe(
  map((response:Response)=>response.json()));
  }

  private extractContentAll(res: Response, des) {
    var fi = des;
    let blob: Blob = res.blob();
    window['saveAs'](blob,fi);
  }

  // Get single invoice in pdf format 
  getSinglePOrderPDF(porder_id, des){
   //debugger;
          let token = localStorage.getItem('token');
     let headers = new Headers({ 'Authorization': 'JWT ' + token });
     let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
   return this.http.get(this.baseUrl+this.apiList.porder_pdf_view+porder_id+"/", options).pipe(
   map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));
  }

// delete Multiple Purchase Order //
deleteSelectedPOrder(order){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_porder, order, options).pipe(
  map((response:Response)=>response.json()));
  }

// End //

}