import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,
                public jwtHelper: JwtHelperService) { }

    canActivate(route: ActivatedRouteSnapshot, 
                state: RouterStateSnapshot,
                ) {
        //console.log(this.jwtHelper.isTokenExpired());
        if (localStorage.getItem('currentUser') && this.jwtHelper.isTokenExpired() == false ) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
