export class AdminApiListClass {
  constructor() {}
  public user_list : string = "/crm/api/v1/user_list/";
  public paid_user_list : string = "/crm/api/v1/paid_user_list/";
  public trial_user_list : string = "/crm/api/v1/trial_user_list/";
  public me_user_list : string = "/crm/api/v1/me_user_list/";
  public companies_list : string = "/crm/api/v1/companies_list/";
  public search_users : string = "/crm/api/v1/search_users/";
  public payment_view : string = "/crm/api/v1/payment_view/";
  public get_payment_trial : string = "/crm/api/v1/get_payment_trial/";
  public update_trial : string = "/crm/api/v1/update_trial/";
  public update_trial_admin : string = "/crm/api/v1/update_trial_admin/";
  public user_as_per_user : string = "/crm/api/v1/user_as_per_user/";
  public user_roles_api : string = "/crm/api/v1/user_roles_api/";
  public user_roles_update_api : string = "/crm/api/v1/user_roles_update_api/";
  public update_user_as_per_user : string ="/crm/api/v1/update_user_as_per_user/";
}