import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { FormGroup, FormControl, Validators, FormBuilder }
    from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  loading : boolean = false;
  id: number;
  token : string;
  private sub: any;
  email : string;
  form: FormGroup;
  message : string;
  is_success : boolean = false;
  is_error : boolean = false;
  constructor(private fb: FormBuilder, private route: ActivatedRoute, private userService : UserService,private router: Router) {

  }

   ngOnInit() {
     this.sub = this.route.params.subscribe(params => {
  
        //this.id = params['id']; // (+) converts string 'id' to a number
        this.token = params['id']
        console.log("routing token is:", this.token);
        // In a real app: dispatch action to load the details here.
        this.getUserName(this.token);
        this.form = this.fb.group({
               "email": this.email,
               "password":["", Validators.required ]
           });
     });
   }

   getUserName(token){
     this.userService.getUserNameForReset(token)
     .subscribe((res)=>{
    
       this.email = res;
       console.log("data is", this.email);
     })
   }

   onSubmitReset(){
  
     console.log("onSubmit Method called");
     console.log("Form method called", this.form.value, this.token);
     this.userService.resetPasswordForm(this.form.value, this.token)
     .subscribe((res)=>{
   
       this.message = res;
       this.is_success = true;
       setTimeout (() => {
         this.is_success = false;
          this.router.navigate(['login']);
   }, 5000);
       this.form.reset();

       console.log("data is", this.message);
     })
   }


   ngOnDestroy() {
     this.sub.unsubscribe();
   }
}
