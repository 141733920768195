import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response, ResponseContentType  } from '@angular/http';
import { User } from '../models/index';
import { BaseUrlClass, BooksApiListClass } from '../common/index';
//import { saveAs } from 'file-saver';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class TimesheetService {
    private baseUrl : string;
    private token : any;
    constructor(private http: Http,
                private baseUrlClass: BaseUrlClass,
                private apiList: BooksApiListClass
              ){this.baseUrl = baseUrlClass.baseUrl;
                this.token =   localStorage.getItem('token');
                //console.log('token key ,'+this.token[0])
              }

   private jwt() {
       
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let token = localStorage.getItem('token');
        if (currentUser && token) {
            let headers = new Headers({ 'Authorization': 'JWT '+token });
            return new RequestOptions({ headers: headers });
        }
    }

    getAllBillingBudget(){
       let options = this.jwt()
      return this.http.get(this.baseUrl+this.apiList.billing_budget_view, options).pipe(
      map((response:Response)=>response.json()));
  }

  getAllProjects(){
       let options = this.jwt()
      return this.http.get(this.baseUrl+this.apiList.project_view, options).pipe(
      map((response:Response)=>response.json()));
  }

  createProjects(project){
    let options = this.jwt()
   return this.http.post(this.baseUrl+this.apiList.project_view, {'project': project}, options).pipe(
   map((response:Response)=>response.json()));
}

getProjectsDetails(id){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.project_details+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

 updateSingleProjects(project){
    console.log("project id is:"+project.id);
      let options = this.jwt()
     const body = JSON.stringify(project);
   return this.http.put(this.baseUrl+this.apiList.project_details+project.id+"/", body, options).pipe(
   map((response:Response)=>response.json()));   
   }

   //delete Single Customer
 deleteSingleProjects(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.project_details+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

   getAllProjectLogs(first_date, todays_date){
       let options = this.jwt()
      return this.http.get(this.baseUrl+this.apiList.project_log_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
      map((response:Response)=>response.json()));
  }

  createProjectLogs(project_log){
    let options = this.jwt()
   return this.http.post(this.baseUrl+this.apiList.project_log_view, {'project_log': project_log}, options).pipe(
   map((response:Response)=>response.json()));
}

getProjectLogsDetails(id){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.project_log_details+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

 updateSingleProjectLogs(project_log){
    console.log("customer id is:"+project_log.id);
      let options = this.jwt()
     const body = JSON.stringify(project_log);
   return this.http.put(this.baseUrl+this.apiList.project_log_details+project_log.id+"/", body, options).pipe(
   map((response:Response)=>response.json()));   
   }

   //delete Single Customer
 deleteSingleProjectLogs(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.project_log_details+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

   updateProjectUsers(prj_id, user){
    console.log("project id is:"+prj_id);
      let options = this.jwt()
     const body = JSON.stringify(user);
   return this.http.put(this.baseUrl+this.apiList.project_users_details+prj_id+"/", body, options).pipe(
   map((response:Response)=>response.json()));   
   }

   //delete Single Customer
 deleteSingleProjectUsers(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.project_users_details+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

   updateProjectTasks(prj_id, task){
    console.log("project id is:"+prj_id);
      let options = this.jwt()
     const body = JSON.stringify(task);
   return this.http.put(this.baseUrl+this.apiList.project_tasks_details+prj_id+"/", body, options).pipe(
   map((response:Response)=>response.json()));   
   }

   //delete Single Customer
 deleteSingleProjectTasks(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.project_tasks_details+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

getProjectBasedLogs(id){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.project_based_logs+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

 updateProjectAsActive(id){
   let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.project_as_active+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
 }

  updateProjectAsInactive(id){
   let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.project_as_inactive+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
 }

 updateProjectTaskAsActive(id){
   let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.task_as_active+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
 }

  updateProjectTaskAsInactive(id){
   let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.task_as_inactive+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
 }

 getProjectPurchaseList(id){
  let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.project_purchase_list+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
 }

 getProjectSalesList(id){
   let options = this.jwt()
   return this.http.get(this.baseUrl+this.apiList.project_sales_list+id+"/", options).pipe(
   map((response:Response)=>response.json()));   
 }

 getBarchartProjectLog(id){
   let options = this.jwt()
   return this.http.get(this.baseUrl+this.apiList.barchart_project_logs+id+"/", options).pipe(
   map((response:Response)=>response.json()));   
 }

// delete or Inactive Multiple Project and Log Time //
deleteSelectedProject(project){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_project, project, options).pipe(
  map((response:Response)=>response.json()));
  }

InactiveSelectedProject(project){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.inactive_selected_project, project, options).pipe(
  map((response:Response)=>response.json()));
  }

deleteSelectedLogTime(logtime){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_logtime, logtime, options).pipe(
  map((response:Response)=>response.json()));
  }
// End //



}