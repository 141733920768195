export class BaseUrlClass {

    public baseUrl: string;
    constructor() {
        // this.baseUrl = 'http://www.axatcloud.com:8070';
        // this.baseUrl  = 'http://34.212.231.173:8070';
        //this.baseUrl = 'http://18.220.239.163:8000';
        // this.baseUrl = 'http://18.116.182.114:8000';
       // this.baseUrl = 'http://13.59.163.11:8000';
       this.baseUrl ='https://axatcloud.com/api'
    }
}	