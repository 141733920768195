import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, UserService } from '../services/index';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from './custom-validator';
import { CustomValidatorsForEmail } from './email-validators';
import { User } from '../models/user';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
    //complexForm : FormGroup;
    user : any;
   ngOnInit() {
     this.meta.addTag({ name: 'robots', content: 'noindex,nofollow' });
       this.user = {
         first_name : '',
         last_name : '',
         email: '',
         password: '',
         confirm_password: '',
         is_marketing_campaigns:false
       }
            }

  //model: any = {};
  loading = false;

  constructor(private router: Router,private userService: UserService,
      private alertService: AlertService, fb : FormBuilder,
      private meta: Meta,) {
     
    }

is_signup : boolean = false;
is_registered_successfully: boolean = false;

  save(model: User, isValid: boolean) {
   // call API to save customer
   debugger;
   console.log(model, isValid);
    this.userService.create(model)
        .subscribe(
            data => {
                this.is_signup = false;
                this.is_registered_successfully = true;
                  setTimeout(()=>{
                   this.is_registered_successfully = false; 
                   this.router.navigate(['/login']);
                  }, 10000)
                //this.alertService.success('Registration successful', true);
                
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
                console.log("This is Failed");
                  this.is_signup = true;
                  setTimeout(()=>{
                   this.is_signup = false; 
                  }, 10000)
            });
 }
}
