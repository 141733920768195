import { Component, OnInit } from '@angular/core';
//import { HeaderComponent } from '../header/header.component';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService, UserService, AxatBookService } from '../services/index';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AxatBooksComponent } from '../axat-books/axat-books.component';
import { Title } from '@angular/platform-browser';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  model: any = {};
  loading = false;
  returnUrl: string;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private alertService: AlertService,
      private userService: UserService,
      public jwtHelper: JwtHelperService, 
      private axatBookService: AxatBookService,
      private meta: Meta,
      private titleService: Title) 
  { 
    
  }

  ngOnInit() {
    //this.titleService.setTitle('My awesome app');
      // reset login status
      //location.reload(true);
      this.meta.addTag({ name: 'robots', content: 'noindex,nofollow' });
      this.authenticationService.logout();
      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

is_login : boolean = false;
  login() {
      this.loading = true;
      localStorage.removeItem('token');
      this.authenticationService.login(this.model.email, this.model.password)
          .subscribe(
              data => {
                
                this.getAllCompanyList();
              },
              error => {
                  this.alertService.error(error);
                  this.loading = false;
                  console.log("This is Failed");
                  this.is_login = true;
                  setTimeout(()=>{
                   this.is_login = false; 
                  }, 5000)
              });
  }

checkNavigationIsItFirstTime(){
    this.loading = true;
    
  }


  all_company_list : any;
temp: any;
//method
getAllCompanyList(){
  console.log("get all company list");
  this.axatBookService.getAllCompany()
  .subscribe((res)=>{
    this.all_company_list = res;
   if (this.all_company_list.length >0){
    for(let i = 0 ; i <= this.all_company_list.length; i++){
      if(this.all_company_list[i].is_default === true ){
        this.all_company_list = this.all_company_list[i].company;
      }else{
        console.log("-----------no-----------------")
      }
    }
  }

    this.getCompanyCount();
    
  })
}


counter:any;
  getCompanyCount(){
    this.userService.getAllCompanyCount()
     .subscribe((res)=>{
       this.counter = res.company_count;

       if (this.returnUrl == '/'){
        if (this.counter <= 0){
          console.log("counter is equal to zero");
          this.router.navigate(['/book/addcompany/']);
      }else{
        let user = JSON.parse(localStorage.getItem('currentUser'));
        if (user.id === this.all_company_list.user_id){
          console.log("counter is greater than or equal to one");
        this.router.navigate(['/book/dashboard']);  
        }else{
          this.router.navigate(['/book/setting/managecompany']); 
        }
        
        }
      }else{
        this.router.navigateByUrl(this.returnUrl);
      }
     })
   
 }
}
