
import {map, startWith} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,  Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';


import { BaseUrlClass } from '../common/index';
// import services here

import { AxatBookService, SharedService, UserService } from '../services/index';

// import profile model here
import { UserOtherInfo } from '../models/user_other_info';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  passwordChangeForm: FormGroup; 
  countryCtrl: FormControl;
  filteredCountry: any;
  user : UserOtherInfo ;
  countrys = [
  'Afghanistan',
	'Åland Islands',
	'Zimbabwe'
 ];

  constructor(private axatBookService : AxatBookService, 
              private fb: FormBuilder,
              private router: Router,
              private baseUrlClass : BaseUrlClass,
              private sharedService: SharedService,
              private userService : UserService
              ) {
    this.countryCtrl = new FormControl();
    this.filteredCountry = this.countryCtrl.valueChanges.pipe(
        startWith(null),
        map(name => this.filterCountry(name)),);
  
    this.createPasswordChangeForm();
  }

  filterCountry(val: string) {
    return val ? this.countrys.filter(s => s.toLowerCase().indexOf(val.toLowerCase()) === 0)
               : this.countrys;
  }
url: any;
  ngOnInit() {
    this.getUserProfileInfo();
    this.getCompanyCount();
  }
// declare user here
	user_profile_info : any[];
email: any;
  // get user profile info
  getUserProfileInfo(){
  	console.log("get user profile info");
  	this.axatBookService.getUserProfile()
  	.subscribe((res)=>{
  		this.user = res;
      this.email = res.user.email;
      this.url = this.baseUrlClass.baseUrl+res.user.profile_pic;
  	})
  }

    fileList : any ;

readUrl(event) {
  if (event.target.files && event.target.files[0]) {
    var reader = new FileReader();
    let fileList: FileList = event.target.files[0];
    
    reader.onload = (event:any) => {
      this.url = event.target.result;
       
      
    }
    //this.user.user.profile_pic = event.target.files[0].name;
    this.fileList = fileList;
    reader.readAsDataURL(event.target.files[0]);
    
  }
}


  // post profile form 

  is_profile_updated : boolean = false;

  onSubmitProfileForm(f){
    debugger;
     console.log("user profile info "+this.user);
    this.axatBookService.createUserProfile(this.user, this.fileList)
    .subscribe((res)=>{
      this.sharedService.sendMessage('Refresh Header!');
      this.is_profile_updated = true;
      console.log("Form Successfully submitted");
    })

  }
    
    
    createPasswordChangeForm() {
    this.passwordChangeForm = this.fb.group({
       email:[''],
       current_password: ['', [Validators.required , Validators.minLength(8), Validators.maxLength(40)]],  
       new_password : ['', [Validators.required, Validators.minLength(8), Validators.maxLength(40)]], 
       new_password_confirm : ['', [Validators.required, Validators.minLength(8), Validators.maxLength(40)]]
    });
  }
 
msg: string;
is_password_updated = false;
is_password_not_updated = false;

onSubmitPasswordChange(){

  this.passwordChangeForm.controls['email'].setValue(this.email);
  let password = this.passwordChangeForm.value;
  this.axatBookService.createNewPassword(password)
    .subscribe((res)=>{

      if(res.success == "Please Enter Correct Current Password"){
        //this.msg = "";
        this.msg = res.success;
         this.is_password_updated = false;
        this.is_password_not_updated = true;

      }else if(res.success == "Password Does Not Match"){
        //this.msg = "";
        this.msg = res.success;
        this.is_password_updated = false;
        this.is_password_not_updated = true;
      }else if(res.success == "Password Match"){
        this.msg = "Password Successfully Changed";
        this.is_password_not_updated = false;
        this.is_password_updated = true;
      }    
      console.log("Form Successfully submitted");
    }
    )

}

counter: any;
getCompanyCount(){
  this.userService.getAllCompanyCount()
   .subscribe((res)=>{
     this.counter = res.company_count;
   })
 
}


gotoBack(){
    if (this.counter > 0 && this.user.user.groups === 1){
      this.router.navigate(['/book/dashboard']);
    }else if(this.counter > 0 && this.user.user.groups !== 1){
      this.router.navigate(['/book/managecompany']);
    }else{
      this.router.navigate(['/axatapp']);
    }
}



}
