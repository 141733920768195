import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AxatBookService, SharedService, UserService } from '../services/index';
import { BaseUrlClass } from '../common/index';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-header-basic',
  templateUrl: './header-basic.component.html',
  styleUrls: ['./header-basic.component.css'],
  host: {
    '(document:click)': 'onClick($event)',
  }
})
export class HeaderBasicComponent implements OnInit {
  message: any;
  subs: Subscription;
  url: any;
  ngOnInit() {
    this.getUSerProfile();
    this.getCompanyCount();
    this.url = this.baseUrlClass.baseUrl;
  }
  // dropdown on right side
  userDropdown:boolean;
  constructor(private _eref: ElementRef,private router: Router,
              private userService : UserService,
              private axatBookService :AxatBookService,
              private baseUrlClass : BaseUrlClass,
              private sharedService: SharedService, ) {
    this.userDropdown = false;
    this.subs = this.sharedService.getMessage().subscribe(message => { 
      this.message = message; 
      if(this.message.text == "Refresh Header!"){
        this.getUSerProfile();
    }
    });

  }


  onClick(event) {
    if (this._eref.nativeElement.contains(event.target) == false){
      this.userDropdown = false;
    } 
      else if(this.userDropdown = !this.userDropdown){
        //console.log("else if");
    }else{
      this.userDropdown = false;
    }
  }

  counter: any;
  getCompanyCount(){
    this.userService.getAllCompanyCount()
     .subscribe((res)=>{
       this.counter = res.company_count;
     })
   
 }

  user_profile : any;
getUSerProfile(){
  console.log("get user profile")
  this.axatBookService.getUserProfile()
  .subscribe((res)=>{
    this.user_profile =  res.user;
    console.log("user profile is:"+this.user_profile);
  })

}

  
logoutPage(){
  console.log("logout page");
  localStorage.removeItem("token");
   this.router.navigate(['/login']);
   window.location.reload();
  }

}


