import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, RequestOptions, Response, ResponseContentType  } from '@angular/http';
import { User } from '../models/index';
import { BaseUrlClass, BooksApiListClass } from '../common/index';
//import { saveAs } from 'file-saver';
import { Observable ,  of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class CreditNoteService {
    private baseUrl : string;
    private token : any;
    constructor(private http: Http,
                private baseUrlClass: BaseUrlClass,
                private apiList: BooksApiListClass
              ){this.baseUrl = baseUrlClass.baseUrl;
                this.token =   localStorage.getItem('token');
                //console.log('token key ,'+this.token[0])
              }

   private jwt() {
       
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let token = localStorage.getItem('token');
        if (currentUser && token) {
            let headers = new Headers({ 'Authorization': 'JWT '+token });
            return new RequestOptions({ headers: headers });
        }
    }

  getAllCreditNotes(first_date, todays_date){
       let options = this.jwt()
    return this.http.get(this.baseUrl+this.apiList.creditnotes_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
    map((response:Response)=>response.json()));
  }

createCreditNotes(credit_notes){
    let options = this.jwt()
 return this.http.post(this.baseUrl+this.apiList.creditnotes_view, {'credit_notes': credit_notes}, options).pipe(
 map((response:Response)=>response.json()));
}

 getCreditNotesDetails(id){
    let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.creditnotes_detail+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

 updateSingleCreditNote(creditnote){
    console.log("customer id is:"+creditnote.id);
      let options = this.jwt()
     const body = JSON.stringify(creditnote);
   return this.http.put(this.baseUrl+this.apiList.creditnotes_detail+creditnote.id+"/", body, options).pipe(
   map((response:Response)=>response.json()));   
   }

   //delete Single Customer
 deleteSingleCreditNotes(id){
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.creditnotes_detail+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

  createRefundAgainstCredit(refund){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.refund_against_creditnotes, {'refund': refund}, options).pipe(
    map((response:Response)=>response.json()));
}

 // get Credit Details 
 getCreditNumber(){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.get_credit_no, options).pipe(
map((response:Response)=>response.json()));   
}


// create Credit No
createNewCreditNo(credit_no){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.get_credit_no, {'credit_no': credit_no}, options).pipe(
map((response:Response)=>response.json()));
}

creditNumberExists(credit_no){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.credit_no_exists,{'credit_no': credit_no}, options).pipe(
map((response:Response)=>response.json()));
}

getReasonList(){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.reason_view, options).pipe(
map((response:Response)=>response.json()));   
}

credit_pr_id:any;
 deleteSingleCreditProducts(product){
   if(product.id){
   this.credit_pr_id =product.id
 }else{this.credit_pr_id=-1}
    let options = this.jwt()
  return this.http.delete(this.baseUrl+this.apiList.single_credit_product+this.credit_pr_id+"/", options).pipe(
  map((response:Response)=>response.json()));   
  }

  private extractContentAll(res: Response, des) {
    var fi = des;
    let blob: Blob = res.blob();
    window['saveAs'](blob,fi);
  }

  credit_no : any;
  // Get single invoice in pdf format 
  getSingleCreditPDF(credit_id, des){
   //debugger;
          let token = localStorage.getItem('token');
     this.credit_no = des;
     let headers = new Headers({ 'Authorization': 'JWT ' + token });
     let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
   return this.http.get(this.baseUrl+this.apiList.credit_pdf_view+credit_id+"/", options).pipe(
   map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));
  }

   // get Customer Details 
 getAllInvoiceCredits(id){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.all_invoice_credit+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

createInvoiceCredit(credit, all_invoices){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.create_invoice_credit, {'credit': credit, 'all_invoices': all_invoices}, options).pipe(
map((response:Response)=>response.json()));
}

createCreditNotesAgainstInvoice(credit_notes){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.create_credit_against_invoice, {'credit_notes': credit_notes}, options).pipe(
map((response:Response)=>response.json()));
}

deleteSingleCreditInvoice(id){
  let options = this.jwt()
return this.http.delete(this.baseUrl+this.apiList.credit_invoice_api+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

getRefundCreditDetails(id){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.refund_against_creditnotes_api+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

updateSingleRefundCredit(creditnote){
  console.log("customer id is:"+creditnote.id);
    let options = this.jwt()
   const body = JSON.stringify(creditnote);
 return this.http.put(this.baseUrl+this.apiList.refund_against_creditnotes_api+creditnote.id+"/", body, options).pipe(
 map((response:Response)=>response.json()));   
 }

 //delete Single Customer
deleteSingleRefundCredit(id){
  let options = this.jwt()
return this.http.delete(this.baseUrl+this.apiList.refund_against_creditnotes_api+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

// END //

// Services of Vendor Credits Module //

getAllVendorCredits(first_date, todays_date){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.vendorcredits_view+"?first_date="+first_date+"&todays_date="+todays_date, options).pipe(
map((response:Response)=>response.json()));
}

createVendorCredits(vendor_credit){
let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.vendorcredits_view, {'vendor_credit': vendor_credit}, options).pipe(
map((response:Response)=>response.json()));
}

getVendorCreditNumber(){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.get_vendor_credit_no, options).pipe(
map((response:Response)=>response.json()));   
}


// create Credit No
createNewVendorCreditNo(credit_no){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.get_vendor_credit_no, {'credit_no': credit_no}, options).pipe(
map((response:Response)=>response.json()));
}

vendorcreditNumberExists(credit_no){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.vendor_credit_no_exists,{'credit_no': credit_no}, options).pipe(
map((response:Response)=>response.json()));
}

// get Bills Details 
getAllBillsCredits(id){
  let options = this.jwt()
  return this.http.get(this.baseUrl+this.apiList.all_bills_credit+id+"/", options).pipe(
  map((response:Response)=>response.json()));   
}

getVendorCreditsDetails(id){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.vendor_credits_detail+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

updateSingleVendorCredits(creditnote){
  console.log("customer id is:"+creditnote.id);
    let options = this.jwt()
   const body = JSON.stringify(creditnote);
 return this.http.put(this.baseUrl+this.apiList.vendor_credits_detail+creditnote.id+"/", body, options).pipe(
 map((response:Response)=>response.json()));   
 }

 //delete Single Customer
deleteSingleVendorCredits(id){
  let options = this.jwt()
return this.http.delete(this.baseUrl+this.apiList.vendor_credits_detail+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

createRefundAgainsVendorCredit(refund){
  let options = this.jwt()
  return this.http.post(this.baseUrl+this.apiList.refund_against_vendor_credits, {'refund': refund}, options).pipe(
  map((response:Response)=>response.json()));
}

getSingleVendorCreditPDF(credit_id, des){
  //debugger;
    let token = localStorage.getItem('token');
    let headers = new Headers({ 'Authorization': 'JWT ' + token });
    let options = new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
  return this.http.get(this.baseUrl+this.apiList.vendor_credit_pdf_view+credit_id+"/", options).pipe(
  map((response:Response)=>{
   let blob : Blob = response.blob();
   window['saveAs'](blob, des);
   }));
 }

 createCreditAgainstBill(credit){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.create_credit_against_bill, {'credit': credit}, options).pipe(
map((response:Response)=>response.json()));
}  

createBillCredit(credit, all_bills){
  let options = this.jwt()
return this.http.post(this.baseUrl+this.apiList.create_bill_credit, {'credit': credit, 'all_bills': all_bills}, options).pipe(
map((response:Response)=>response.json()));
}

 //delete Single Credit Bills
 deleteSingleCreditBills(id){
  let options = this.jwt()
return this.http.delete(this.baseUrl+this.apiList.credit_bill_api+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

getRefundVendorCreditDetails(id){
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.refund_against_vendor_credits_api+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

updateSingleRefundVendorCredit(creditnote){
  console.log("customer id is:"+creditnote.id);
    let options = this.jwt()
   const body = JSON.stringify(creditnote);
 return this.http.put(this.baseUrl+this.apiList.refund_against_vendor_credits_api+creditnote.id+"/", body, options).pipe(
 map((response:Response)=>response.json()));   
 }

 //delete Single Customer
deleteSingleRefundVendorCredit(id){
  let options = this.jwt()
return this.http.delete(this.baseUrl+this.apiList.refund_against_vendor_credits_api+id+"/", options).pipe(
map((response:Response)=>response.json()));   
}

// End //

// delete Multiple Credits and Vendor Credits //
deleteSelectedCredits(credits){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_credits, credits, options).pipe(
  map((response:Response)=>response.json()));
  }

deleteSelectedVCredits(credits){
    let options = this.jwt()
    return this.http.post(this.baseUrl+this.apiList.delete_selected_vcredits, credits, options).pipe(
  map((response:Response)=>response.json()));
  }
// End //



 
}