
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { User } from '../models/index';
import { BaseUrlClass, ApiListClass } from '../common/index';

@Injectable()
export class UserService {
    private baseUrl : string;
    private token : any;
    constructor(private http: Http, private baseUrlClass: BaseUrlClass, private apiList: ApiListClass ) {
        this.baseUrl = baseUrlClass.baseUrl;
        this.token =   localStorage.getItem('token');
    }

    create(user: User) {
        //debugger;
        return this.http.post(this.baseUrl+this.apiList.sign_up, user).pipe(map((response: Response) => response.json()));
    }

    forgot(user: string) {
        //debugger;
        console.log("user is:",user);
        return this.http.post(this.baseUrl+this.apiList.forgot, JSON.stringify({ user: user }), JSON.stringify({ user: user })).pipe(
          map((response: Response) => response.json()));
    }

    getUserNameForReset(token: string){
      console.log("user is:",token);
      //debugger;
      return this.http.post(this.baseUrl+this.apiList.reset, JSON.stringify({ token: token }), JSON.stringify({ token: token })).pipe(
        map((response: Response) => response.json()));
    }

    resetPasswordForm(user: any, token: string){
      console.log("user is:",user);
      //debugger;
      return this.http.post(this.baseUrl+this.apiList.reset_success, JSON.stringify({ user: user , token:token }), JSON.stringify({ user: user, token:token })).pipe(
        map((response: Response) => response.json()));

    }

    checkEmailExistOrNot(email: any){
      console.log("user is:",email);
      //debugger;
      return this.http.post(this.baseUrl+this.apiList.is_email_exist, JSON.stringify({ email: email  })).pipe(
        map((response: Response) => response.json()));

    }

// getAllCompanyCount //company_count

getAllCompanyCount(){
  //debugger;
  let options = this.jwt()
return this.http.get(this.baseUrl+this.apiList.company_count, options).pipe(
map((response:Response)=>response.json()));
  
}


    private jwt() {
        //debugger;
        // create authorization header with jwt token
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let token = localStorage.getItem('token');
        if (currentUser && token) {
            let headers = new Headers({ 'Authorization': 'JWT '+token });
            return new RequestOptions({ headers: headers });
        }
    }
}
