// all constants declare here
export class BooksApiListClass {
  constructor() {}
  public currency : string = "/books/api/v1/currency/";
  public place_of_supply : string = "/books/api/v1/place_of_supply/";
  public customer : string ="/books/api/v1/customer/";
  public invoice_type : string ="/books/api/v1/invoice_type/";
  public tax : string ="/books/api/v1/tax/";
  public product_category : string = "/books/api/v1/product_category/";
  public company : string = "/books/api/v1/company/";
  public income_account : string = "/books/api/v1/income_account/";
  public expense_account : string = "/books/api/v1/expense_account/";
  public inventory : string = "/books/api/v1/product_inventory/";
  public hsn : string = "/books/api/v1/hsn_code/";
  public tax_include : string ="/books/api/v1/tax_include_on_amount/";
  public sales_person : string = "/books/api/v1/sales_person/";
  public create_invoice : string = "/books/api/v1/create_invoice/";
  public profile : string = "/books/api/v1/user_profile/";  // get , post , delete method called
  public company_count : string = "/books/api/v1/api/v1/company_count/";
  public company_setting : string = "/books/api/v1/company/";
  public expense_type  : string = "/books/api/v1/expense_type/";
  public countries : string = "/books/api/v1/countries_view/";
  public fiscal_year : string = "/books/api/v1/fiscal_year_view/";
  public accounting_method : string = "/books/api/v1/accounting_method/";
  public company_default : string = "/books/api/v1/make_company_default/";
  public invoice_pdf : string = "/books/api/v1/invoice_pdf_view/";
  public update_footer_singnature : string = "/books/api/v1/update-footer-singature/";

  public invoice_list : string = "/books/api/v1/invoice_list/";
  public single_invoice : string = "/books/api/v1/invoice_update_view/";
  public invoice_copy : string = "/books/api/v1/copy-invoice/";
  public gst_tratment : string = "/books/api/v1/gst_treatment/";
  public customer_edit : string = "/books/api/v1/customer_edit/";
  public product: string ="/books/api/v1/product/";
  public inventory_data : string ="/books/api/v1/get_inventory_stock_data/";
  public inventory_quantity_in_hand : string ="/books/api/v1/inventory_quantity_in_hand/";
  public inventory_out_of_stock_list : string ="/books/api/v1/inventory_out_of_stock_list/";
  public inventory_low_stock_list : string ="/books/api/v1/inventory_low_stock_list/";
  public inventory_total : string ="/books/api/v1/inventory_total_list/";
  public customer_analytic_data : string ="/books/api/v1/customer_analytic_data/";
  public product_analytic_data : string ="/books/api/v1/product_analytic_data/";
  public customer_all_data : string ="/books/api/v1/customer_all_data/";
  public vendor_all_data : string ="/books/api/v1/vendor_all_data/";
  public customer_autosearch : string ="/books/api/v1/customer-view-autosearch/";
  public vendor_autosearch : string ="/books/api/v1/vendor-view-autosearch/";
  public financial_start_end_date : string = "/books/api/v1/financial_start_end_date/"
  
//vendor_autosearch
  public product_autosearch : string ="/books/api/v1/product-view-autosearch/";
  public get_invoice_no: string ="/books/api/v1/get_invoice_no/";
  public terms: string ="/books/api/v1/terms/";
  public invoice_analytic_data : string ="/books/api/v1/invoice_analytic_data/";
  public totalamount_invoice : string ="/books/api/v1/totalamount_invoice/";
  public paidamount_invoice : string ="/books/api/v1/paidamount_invoice/";
  public dueamount_invoice : string ="/books/api/v1/dueamount_invoice/";
  public import_customer : string ="/books/api/v1/import_customer/";
  public import_vendor : string ="/books/api/v1/import_vendor/";
  public import_products : string ="/books/api/v1/import_products/";
  public single_invoice_products: string = "/books/api/v1/single_invoice_products/";
  public paid_through : string = "/books/api/v1/paid_through/";
  public expenses : string ="/books/api/v1/expenses/";

  public make_payment: string = "/books/api/v1/make_payment/";
  public sales_by_customer: string = "/books/api/v1/sales_by_customer/";
  public sales_by_salesperson: string = "/books/api/v1/sales_by_salesperson/";
  public customer_invoice_autosearch : string ="/books/api/v1/customer-invoice-view-autosearch/";
  public payment_list: string = "/books/api/v1/payment_list/";
  public single_payment : string = "/books/api/v1/single_payment/";
  public expense_account_type : string ="/books/api/v1/expense_accounts_type/";
  public supplier_bill : string ="/books/api/v1/supplier_bill/";
  public expense_update : string = "/books/api/v1/expense_update/";
  public payment_pdf: string = "/books/api/v1/payment_pdf/";
  public get_supplier_bill : string ="/books/api/v1/get_supplier_bill/";

  public get_supplier_products : string ="/books/api/v1/get_all_supplier_products/";
  public get_supplier_bill_pdf : string = "/books/api/v1/purchase_bill_pdf_view/";
  public bill_analytics : string = "/books/api/v1/bill_analytic_data/";
// supplier bill 
  public create_company_with_logo : string ="/books/api/v1/create_company_with_logo/";

  public bill_payment: string = "/books/api/v1/bill_payment/"
  public vendor_bill_autosearch : string ="/books/api/v1/vendor-bill-view-autosearch/";
  public bill_payment_update : string = "/books/api/v1/bill_payment_update/";
  public bill_payment_list : string = "/books/api/v1/bill_payment_list/";
  public bill_payment_pdf : string = "/books/api/v1/bill_payment_pdf/";



  public get_payment_method : string = "/books/api/v1/get_payment_method/";
  public get_depositto : string = "/books/api/v1/get_depositto/";

  public company_currency : string = "/books/api/v1/company_currency/";
  public invoice_no_exists : string = "/books/api/v1/invoice_no_exists/";

  public dashboard_view : string = "/books/api/v1/dashboard_view/";
  public gstr_pdf : string = "/books/api/v1/gstr_pdf/";

  public top_5_expenses_data : string ="/books/api/v1/top_expense/";
  public cash_flow_data : string ="/books/api/v1/cash_flow/";
  public income_and_expense_data : string = "/books/api/v1/income_and_expense/";

  public tax_analytics_data : string = "/books/api/v1/tax_analytics_data/";
  public tax_detail : string = "/books/api/v1/tax_detail/";

  public bar_chart_yaxis: string = "/books/api/v1/bar_chart_yaxis/";

  public change_password: string = "/books/api/v1/change_password/";
  public expense_gst_treatment : string = "/books/api/v1/expense_gst_treatment/";
  public get_gstr_report : string = "/books/api/v1/get_gstr_report/";
  public get_gstr_bill_report : string = "/books/api/v1/get_gstr_bill_report/";
  public get_gstr_inter_report : string = "/books/api/v1/get_gstr_inter_report/";
  public get_gstr_itc_report : string = "/books/api/v1/get_gstr_itc_report/";
  public top_expense_view: string= "/books/api/v1/top_expense_view/";
  public expense_drilldown: string= "/books/api/v1/expense_drilldown/";

  public b2b_gstr: string= "/books/api/v1/b2b_gstr/";
  public b2b_large_gstr: string="/books/api/v1/b2b_large_gstr/";
  public b2b_small_gstr: string="/books/api/v1/b2b_small_gstr/";
  public export_gstr: string="/books/api/v1/export_gstr/";
  public b2b_other_gstr: string="/books/api/v1/b2b_other_gstr/";
  public exempt_gstr: string="/books/api/v1/exempt_gstr/";
  public hsn_gstr: string="/books/api/v1/hsn_gstr/";
  public invoice_excel_view: string="/books/api/v1/invoice_excel_view/";
  public expense_excel_view: string="/books/api/v1/expense_excel_view/";
  public bill_excel_view: string="/books/api/v1/bill_excel_view/";
  public set_invoiceno_accord_invoice: string="/books/api/v1/set_invoiceno_accord_invoice/";

  public currency_company_wise: string="/books/api/v1/currency_company_wise/";
  public currency_company_wise_details: string="/books/api/v1/currency_company_wise_details/";
  public update_gst_treatment: string="/books/api/v1/update_gst_treatment/";
  public get_all_tax_account: string="/books/api/v1/get_all_tax_account/";

  public get_income_list: string="/books/api/v1/get_income_list/";
  public get_bills_expense_list: string="/books/api/v1/get_bills_expense_list/";

  public delete_selected_contacts: string="/books/api/v1/delete_selected_contacts/";
  public delete_selected_products: string="/books/api/v1/delete_selected_products/";
  public delete_selected_invoices: string="/books/api/v1/delete_selected_invoices/";
  public delete_selected_invpayment: string="/books/api/v1/delete_selected_invpayment/";
  public delete_selected_bills: string="/books/api/v1/delete_selected_bills/";
  public delete_selected_billspayment: string="/books/api/v1/delete_selected_billspayment/";
  public delete_selected_expenses: string="/books/api/v1/delete_selected_expenses/";

  //lenders Urls
  public lenders_view: string="/books/lenders/api/v1/lenders_view/";
  public get_lenders_view: string="/books/lenders/api/v1/get_lenders_view/";
  public lender_pdf_view: string="/books/lenders/api/v1/lender_pdf_view/";
  public lend_payment_list: string="/books/lenders/api/v1/payment_list/";
  public lend_payment_view: string="/books/lenders/api/v1/get_payment_view/";
  public lender_payment_pdf_view: string="/books/lenders/api/v1/lender_payment_pdf_view/";
  public lender_payment_accord_lend: string="/books/lenders/api/v1/lender_payment_accord_lend/";
  public lender_number_auto: string="/books/lenders/api/v1/lender_number_auto/";
  public delete_selected_lend: string="/books/lenders/api/v1/delete_selected_lend/";
  public delete_selected_lpayment: string="/books/lenders/api/v1/delete_selected_lpayment/";

  // Credit Notes Urls
  public creditnotes_view: string="/books/credit_notes/api/v1/creditnotes_view/";
  public creditnotes_detail: string = "/books/credit_notes/api/v1/creditnotes_detail/";
  public refund_against_creditnotes: string = "/books/credit_notes/api/v1/refund_against_creditnotes/";
  public get_credit_no: string ="/books/credit_notes/api/v1/get_credit_no/";
  public credit_no_exists: string ="/books/credit_notes/api/v1/credit_no_exists/";
  public reason_view: string ="/books/credit_notes/api/v1/reason_view/";
  public single_credit_product: string ="/books/credit_notes/api/v1/single_credit_product/";
  public credit_pdf_view: string ="/books/credit_notes/api/v1/credit_pdf_view/";
  public all_invoice_credit: string ="/books/credit_notes/api/v1/all_invoice_credit/";
  public create_invoice_credit: string ="/books/credit_notes/api/v1/create_invoice_credit/";
  public create_credit_against_invoice: string = "/books/credit_notes/api/v1/create_credit_against_invoice/";
  public credit_invoice_api: string ="/books/credit_notes/api/v1/credit_invoice_api/";
  public refund_against_creditnotes_api: string = "/books/credit_notes/api/v1/refund_against_creditnotes_api/";
  public delete_selected_credits: string="/books/credit_notes/api/v1/delete_selected_credits/";


  //Vendor Credits Urls
  public vendorcredits_view: string = "/books/credit_notes/api/v1/vendorcredits_view/";
  public get_vendor_credit_no: string ="/books/credit_notes/api/v1/get_vendor_credit_no/";
  public vendor_credit_no_exists: string ="/books/credit_notes/api/v1/vendor_credit_no_exists/";
  public all_bills_credit: string ="/books/credit_notes/api/v1/all_bills_credit/";
  public vendor_credits_detail: string = "/books/credit_notes/api/v1/vendor_credits_detail/";
  public refund_against_vendor_credits: string = "/books/credit_notes/api/v1/refund_against_vendor_credits/";
  public vendor_credit_pdf_view: string ="/books/credit_notes/api/v1/vendor_credit_pdf_view/";
  public create_credit_against_bill: string = "/books/credit_notes/api/v1/create_credit_against_bill/";
  public create_bill_credit: string ="/books/credit_notes/api/v1/create_bill_credit/";
  public credit_bill_api: string ="/books/credit_notes/api/v1/credit_bill_api/";
  public refund_against_vendor_credits_api: string = "/books/credit_notes/api/v1/refund_against_vendor_credits_api/";
  public delete_selected_vcredits: string="/books/credit_notes/api/v1/delete_selected_vcredits/";

  //Estimates Urls
  public estimate_view: string="/books/estimate/api/v1/estimate_view/";
  public estimate_details: string = "/books/estimate/api/v1/estimate_details/";
  public get_estimate_no: string ="/books/estimate/api/v1/get_estimate_no/";
  public estimate_no_exists: string ="/books/estimate/api/v1/estimate_no_exists/";
  public single_estimate_product: string = "/books/estimate/api/v1/single_estimate_product/";
  public estimate_pdf_view: string = "/books/estimate/api/v1/estimate_pdf_view/";
  public create_estimate_to_invoice: string = "/books/estimate/api/v1/create_estimate_to_invoice/";
  public delete_selected_estimates: string="/books/estimate/api/v1/delete_selected_estimates/";

  //Purchase Order Urls
  public porder_view: string="/books/order/api/v1/porder_view/";
  public porder_details: string = "/books/order/api/v1/porder_details/";
  public get_porder_no: string ="/books/order/api/v1/get_porder_no/";
  public porder_no_exists: string ="/books/order/api/v1/porder_no_exists/";
  public porder_to_bill: string = "/books/order/api/v1/porder_to_bill/";
  public porder_pdf_view: string = "/books/order/api/v1/porder_pdf_view/";
  public delete_selected_porder: string="/books/order/api/v1/delete_selected_porder/";

  //Timesheets Urls
  public project_view: string="/books/timesheet/api/v1/project_view/";
  public project_details: string="/books/timesheet/api/v1/project_details/";
  public project_log_view: string="/books/timesheet/api/v1/project_log_view/";
  public project_log_details: string="/books/timesheet/api/v1/project_log_details/";
  public billing_budget_view: string="/books/timesheet/api/v1/billing_budget_view/";
  public project_users_details: string="/books/timesheet/api/v1/project_users_details/";
  public project_tasks_details: string="/books/timesheet/api/v1/project_tasks_details/"; 
  public project_based_logs: string="/books/timesheet/api/v1/project_based_logs/"; 
  public project_as_active: string="/books/timesheet/api/v1/project_as_active/"; 
  public project_as_inactive: string="/books/timesheet/api/v1/project_as_inactive/"; 
  public task_as_active: string="/books/timesheet/api/v1/task_as_active/"; 
  public task_as_inactive: string="/books/timesheet/api/v1/task_as_inactive/"; 
  public project_sales_list: string="/books/timesheet/api/v1/project_sales_list/"; 
  public project_purchase_list: string="/books/timesheet/api/v1/project_purchase_list/"; 
  public barchart_project_logs: string="/books/timesheet/api/v1/barchart_project_logs/"; 
  public delete_selected_project: string="/books/timesheet/api/v1/delete_selected_project/";
  public inactive_selected_project: string="/books/timesheet/api/v1/inactive_selected_project/";
  public delete_selected_logtime: string="/books/timesheet/api/v1/delete_selected_logtime/";
}
