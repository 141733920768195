import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { AuthGuard } from './guards/index';
import { ForgotComponent } from './forgot/forgot.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AxatCloudAppComponent } from './axat-cloud-app/axat-cloud-app.component';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';

const appRoutes: Routes = [
  { path: 'signup', component: SignupComponent },
  { path: 'login', component: LoginComponent },
  // axat crm component called
  { path: 'crm', loadChildren: './axat-crm/axat-crm.module#AxatCrmModule', canActivate: [AuthGuard]
 },
  //axat books Component called
  { path: 'book', loadChildren: './axat-books/axat-books.module#AxatBooksModule', canActivate: [AuthGuard]
 },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]
 },
  { path: '', component: CommingSoonComponent },
  { path: 'forgot', component: ForgotComponent},
  { path: 'reset/:id', component: ResetPasswordComponent, },
  { path: 'axatapp', component: AxatCloudAppComponent, canActivate: [AuthGuard]
 },
  { path : 'coming-soon', component : CommingSoonComponent},
 
 //{ path: 'admin', loadChildren: './admin/admin.module#AdminModule', canActivate: [AuthGuard]
 //},
  //admin part
  
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes,)
  ],
  exports:[
    RouterModule
  ]
})
export class AppRoutingModule { }
